<template>
  <div class="container-fluid row m-0 p-0 no-gutters">
    <section
      class="d-flex flex-column justify-content-start m-0 py-0 px-5 col position-relative"
    >
      <video
        autoplay
        loop
        muted
        style="
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          z-index: 0;
          object-fit: cover;
        "
        :src="require('../assets/bg-loop.mp4')"
      />
      <div
        class="d-flex justify-content-between align-items-end mt-4 pb-3"
        style="border-bottom: 1px solid var(--primary); z-index: 1"
      >
        <h5 class="text-primary mb-0">Find your developer</h5>
        <button class="rounded-button bg-neutral-light px-5 border-primary">
          <h5
            @click="$router.push({ name: 'CreateProject1' })"
            class="text-neutral-dark font-weight-400 mb-0 text-center cursor-pointer"
          >
            Take me to Dashboard
          </h5>
        </button>
      </div>

      <h2 class="text-neutral-dark mt-5">Please Select Your Developer</h2>
      <div class="d-flex flex-wrap gap-5 mt-5">
        <developer-item
          class="wd-25p h-100"
          v-for="d in developers"
          :key="d.id"
          :developer="d"
          :is-square="true"
          @click.native="
            $router.push({
              name: 'DeveloperProjects',
              params: { developerId: d.id },
            })
          "
        />
      </div>
    </section>

    <section :class="['m-0 p-0 col-3 d-none d-md-block']">
      <img
        :src="require('/src/assets/images/compound-bg.jpeg')"
        alt="tech-bg"
        class="img-height img-section"
      />
    </section>
  </div>
</template>

<script>
import DeveloperItem from "@/components/DeveloperItem";
export default {
  name: "PropertyDevelopers",
  components: { DeveloperItem },
  mounted() {
    this.$store.dispatch("developers/getDevelopers");
  },
  computed: {
    developers() {
      return this.$store.state.developers.developers;
    },
  },
};
</script>

<style scoped lang="scss">
.img-height {
  width: 100%;
  height: 100vh;
  position: sticky;
  top: 0;
  object-fit: cover;
  animation: backgroundScroll ease-in-out 35s infinite;
}

.img-section {
  animation: backgroundScroll ease-in-out 35s infinite;
}

@keyframes backgroundScroll {
  0% {
    object-position: 0;
  }
  50% {
    object-position: 100%;
  }

  100% {
    object-position: 0;
  }
}
</style>
